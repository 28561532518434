import React, { useEffect, useState } from "react";
import Header from './../../Components/Header';
import SearchBar from './../../Components/SearchBar';
import MapGraph from './../../Components/MapGraph';
import './Style.scss';
import data from '../../Data/Config.json';
import { Container } from "react-bootstrap";
import dataTable from '../../Data/DataTable.json';

function HomePage (){
	const user_type = localStorage.getItem("user_type") || '1';
	const [dataMarker,setDataMarker] = useState(data[user_type].location);  
	const[currentDataItem,setCurrentDataItem] = useState({});
	const[currentAllDataItem,setCurrentAllDataItem] = useState({});  
	
	useEffect(()=>{		
		let array = {};
		dataMarker.forEach(element => {
			array[element] = dataTable[element];
		});
		setCurrentAllDataItem(array);
		setCurrentDataItem(array);
	},[dataMarker]);	
	const handleSearchItems = (searchValue) => {
		let search = searchValue.toLowerCase();
		let dataArray = {};		
		
		Object.keys(currentAllDataItem).forEach((keys) => {
			dataArray[keys] = {"productlist":[]};
			let allProducts = currentAllDataItem[keys]['productlist'];			
			allProducts.forEach((element,index) => {
				  if((((element['item_name']+"").toLowerCase()).includes(search)) || 
				  (((element['id']+"").toLowerCase()).includes(search)) || 
				  (((element['batch_no']+"").toLowerCase()).includes(search))){
					dataArray[keys]['productlist'].push(element);
				  }
				 
			  }); 
		}); 
		if(search === ""){
			dataArray = {...currentAllDataItem};
		}  
		setCurrentDataItem(dataArray);	
			     
  	}  
	  
	return (
      <>
	  <Header />
	  <SearchBar setDataMarker={setDataMarker} handleSearchItems={handleSearchItems}  currentDataItem={currentDataItem} setCurrentDataItem={setCurrentDataItem} />
	  <Container>
		<div className="graph--map">
			<MapGraph currentDataItem={currentDataItem} />
		</div>
	  </Container> 
	  
	  </>
	 ) 
}
export default HomePage;
import {useState}  from "react";
import {Form, Button, Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Header from '../../Components/Header';
import './Style.scss';
import Modal from 'react-bootstrap/Modal';
import ProductImg from "../../Static/Images/product1.jpeg"
import Alerttriangle1 from "../../Static/Images/alert-triangle1.svg";
import { QrReader } from 'react-qr-reader';


export default function CustomerBuy() {

  const [show, setShow] = useState(false);
  const[scan, setScan] = useState(false);
  const[inputVal, setInputVal] = useState(false)
  const handleInput= () =>{setInputVal(true)}
  const handleClose = () => setShow(false);
  const handleShow=() =>setShow(true);
  const scancamShow=() => setScan(true);
  const scancamhide=() => setScan(false);

  const handleScanner = () => {
    scancamShow()
    setTimeout(scancamhide, 5000);
    setTimeout(handleInput, 5000);
    setTimeout(handleShow, 6000);
  }

  const handleError = (err) => {
    console.error(err);
  };
 

  return (
    <>
    <Header />
    <Container className= "item-page">
      <Modal show={show} onHide={handleClose} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered dialogClassName="ItemSold_width item_alert">
        <Modal.Header >
          <p className= "modal-offtext">Product Customer Preview</p>
          <Modal.Title> <img src={Alerttriangle1} alt="AlertIcon" /> Please wait, help is on the way</Modal.Title>
        </Modal.Header>
        <Modal.Body>        
          <Row>
            <Col>
              <div className="productImage">
                <img src={ProductImg} alt="productImg" />
              </div>
            </Col>
            <Col className="productdetail_mainclass">
              <div className="product_details">
                <h2>Heinz sunrise banana porridge(240g)</h2>
                <p className="product_UID"><span>UID:</span> #396441</p>
               
               <div className="text-left"> <Button className="btn btn-modal" disabled>Please wait...</Button></div>           
               <div className="Passcode_form">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Enter Passcode</Form.Label>
                      <Form.Control type="text" placeholder="Passcode" />
                    </Form.Group>
                    <Button className="btn btn-managment" onClick={handleClose}>
                      Store Login
                    </Button>
                  </Form>
                 </div>
              </div>
            </Col>
          </Row>          
        </Modal.Body>    
      </Modal> 

      <h2>Buy item</h2>

        <Form className= "item-form">
          <div className="form-header">
            <p>Add details</p>
          </div>
          <div className="form-content">
            <div className="scan-item">
              <p>Scan Item</p> <Button className="scanbtn" onClick={  handleScanner }>Scan Item</Button>
            </div>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Bar Code</Form.Label>
            <Form.Control type="text" placeholder="" defaultValue={inputVal === true ? "010123456789012890TEC" : ""}/>            
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Item Name</Form.Label>
            <Form.Control type="text" placeholder="" defaultValue={inputVal === true ? "Heinz sunrise banana porridge(240g)" : ""} />            
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Product UID</Form.Label>
            <Form.Control type="text" placeholder="" defaultValue={inputVal === true ? "#396441" : "" }/>            
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Item Details</Form.Label>
            <Form.Select >
              <option>None</option>
              <option>item-1</option>
              <option>item-2</option>
            </Form.Select>
          </Form.Group>


          <Row>
            <Col lg="6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Prize</Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={inputVal === true ? "£10" : ""}/>            
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Item Details</Form.Label>
              <Form.Control type="text" placeholder="" defaultValue= {inputVal === true ? "11742" : ""}/>            
              </Form.Group>
            </Col>
          </Row>          
          
          <div className="text-right">
            <Link to="/home" className="btn scanbtn" type="submit"> Save Item </Link>
          </div>
          </div>
      </Form>

      {scan === true && <>
        <div className="scanner_popup">
        <QrReader
          constraints= {{ facingMode: 'user' }}
          scanDelay={500}
          onResult={handleError}
          //onScan={handleScan}
          // chooseDeviceId={()=>selected}
          containerStyle={{ width: "450px", heigth: "400px" }}
        />      
        </div>
      </>}
    </Container>
    </>
  );
}

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { ChevronDown, Download } from 'react-bootstrap-icons';
import './Style.scss';


function Tabletree({products=[]}) { 

const itemFormatter = (cell, row) =><div className="item_img" > 
<img src={row.prod_img} alt="product_image"></img> <p className="item_name">{row.item_name}</p> </div>;

const valueFormatter = (cell, row) =><div className="item_img" > 
<span>£</span><p className="item_name1">{row.value}</p> </div>;

const prizeFormatter = (cell, row) =><div className="item_img" > 
<span>£</span><p className="item_name">{row.price}</p> </div>;

  const columns = [
    { dataField: 'id', text: 'Id',classes: 'light_text', 
      headerStyle: (colum, row) => {
      return { width: '100px' }
    }},
    { dataField: 'item_name', text: 'Item Name', sort: true,  classes: 'dark_text', formatter: itemFormatter, 
    headerStyle: (colum, row) => {
      return { width: '350px' }
    }
  },
    { dataField: 'batch_no', text: 'Batch No', sort: true, classes: 'light_text',
    headerStyle: (colum, row) => {
      return { width: '220px' }
    } },
    { dataField: 'region', text: 'Region', sort: true, classes: 'light_text',
    headerStyle: (colum, row) => {
      return { width: '210px' }
    } },
    { dataField: 'store', text: 'Store', sort: true, classes: 'light_text',
    headerStyle: (colum, row) => {
      return { width: '300px' }
    } },
    { dataField: 'date', text: 'Date', sort: true, classes: 'light_text',
    headerStyle: (colum, row) => {
      return { width: '120px' }
    } },
    { dataField: 'price', text: 'Price', sort: true, classes: 'light_text', formatter: prizeFormatter,
    headerStyle: (colum, row) => {
      return { width: '120px' }
    } },
    { dataField: 'volume', text: 'Volume', sort: true, classes: 'light_text',
    headerStyle: (colum, row) => {
      return { width: '100px' }
    } },
    { dataField: 'value', text: 'Value', sort: true, classes: 'bold_text', formatter: valueFormatter,
    headerStyle: (colum, row) => {
      return { width: '120px' }
    } },
  ];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 15,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });

 // const { SearchBar } = Search;
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="export_btn" onClick={handleClick} >
        <Download className='mr4' color={"#3F434A"}/> Export data <ChevronDown size={12}/>
        </button>
      </div>
    );
  };

  return (
    <>     

      <ToolkitProvider
        bootstrap4
        keyField='batch_no'
        data={products}
        columns={columns}     
        search      
        exportCSV
      >
        {
          props => (
            <div className= "table--main">              
             {/* <SearchBar { ...props.searchProps } /> */}
              <BootstrapTable
                selectRow={ selectRow }
                pagination={pagination}
                {...props.baseProps}                
                className='tree-table'
              />
            <MyExportCSV {...props.csvProps} />
            </div>
          )
        }
      </ToolkitProvider>      
    </>
  );

  
}

export default Tabletree;
import React , {useState} from 'react';
import { Container, Image } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { FormControl } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { Button } from "react-bootstrap";
import './Style.scss';
import data from '../Data/Config.json';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Typeahead } from 'react-bootstrap-typeahead';
import Place from '../Data/Store.json';
import dataTable from '../Data/DataTable.json';
import SearchIcon from '../Static/Images/Search.svg';


export default function SearchBar({setDataMarker,handleSearchItems,currentDataItem,setCurrentDataItem,selectedLoc="All Locations"}) {
    
    
    const [filteredResults, ] = useState([]);  
    const params = useParams();
    const user_type = localStorage.getItem("user_type") || '1';
    const location = data[user_type].location.length === 1 ? true : false; 
    const store = data[user_type].store.length !== 1 ? false : true; 
    const[value, setValue] = useState({});
    const[selectedLocation, setSelectedLocation] = useState([selectedLoc]);
    const[days, setDays] = useState('180 days');
    const options = data[user_type].store;
    function convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("/");
      }
      
    function handleDatePicker(start, end, label){
        const date1 =new Date(convert(start._d)).getTime();
        const date2 = new Date(convert(end._d)).getTime();

        console.log("start date:", date1);
        console.log("End date:", date2);      

       var diffDays = Math.round((date2 - date1) / (1000 * 60 * 60 * 24)); 
       setDays(diffDays.toString() + " Days");
    }
    const handleChange = (e,index)=>{
        let array = {...value};
        if(e.target.checked === true){
            array[index] = e.target.value;
        }else{
            delete array[index];
        }
        let dataArray:any=[];
        let filterArray = {};
        Object.keys(array).forEach(function(obj){
            dataArray.push(array[obj])
            filterArray[array[obj]]  = dataTable[array[obj]];
        });
        if(dataArray.length > 0){
            setDataMarker(dataArray);
            setSelectedLocation(dataArray);
        }else{
            setDataMarker(data[user_type].location);
            setSelectedLocation(["All Locations"]);
            
        }
        setValue(array);
        
    }
   
    const handleFilter = ()=>{
        let array = {};
        if(selectedLocation.length === 1 && selectedLocation[0] === "All Locations"){
            array = dataTable;
        }else{
		selectedLocation.forEach(element => {
			array[element] = dataTable[element];
		});
        }
		setCurrentDataItem(array);
    }  
    

  return (
    <Container className="search-container">
        <p>{filteredResults }</p>		
    <Form className="d-flex search_from">
        <div className="search_box one">
            <label>Search</label>
            <FormControl  placeholder="Search items" className="me-2" aria-label="Search" onChange={(e) => handleSearchItems(e.target.value)}/>
            <Image src={SearchIcon} className="fa-magnifying-glass" />
            
        </div>
        <div className="location_box one">
            <label>Locations</label>				
            <DropdownButton id="dropdown-item-button" className="dropdown_btn" 
            title={(selectedLocation.join(",")).replace("-"," ")} 
            disabled={location} > 
                <Dropdown.ItemText>Location Filter</Dropdown.ItemText>
                { Place.Location.map((obj,index)=>(                   
                    <Form.Check key={index} type="checkbox" label={obj.name.replace("-"," ")} value={obj.name}
                    onChange= {(e)=>{handleChange(e,index); }}/>
                ))}                  
                 <Dropdown.Item> <Button className="drop_btn" onClick={handleFilter}>Save</Button>	</Dropdown.Item>
            </DropdownButton>
        </div>
                
        <div className="store_box one">
            <label>Store</label>	
             <Typeahead
                id="store-typeahead"
                className= "store_dropdown"
                labelKey="name"
                options={options}
                placeholder="Store Name"
                disabled={store}
                defaultInputValue= {data[user_type].store.length === 1 ? "Barry Holton Express" : ""}
            />            
        </div>

        <div className="period_box one">
            <label>Specific Period</label>				
            <DropdownButton id="dropdown-item-button" title={days}> 				
            <Dropdown.Item onClick={()=> setDays("24 Hours")}>24 Hours</Dropdown.Item>
            <Dropdown.Item onClick={()=> setDays("7 Hours")}>7 Days</Dropdown.Item>
            <Dropdown.Item onClick={()=> setDays("30 Days")}>30 Days</Dropdown.Item>
            <Dropdown.Item onClick={()=> setDays("60 Days")}>60 Days</Dropdown.Item>
            <Dropdown.Item onClick={()=> setDays("90 Days")}>90 Days</Dropdown.Item>
            <Dropdown.Item onClick={()=> setDays("180 Days")}>180 Days</Dropdown.Item>
            
            <DateRangePicker onCallback= {handleDatePicker}
                initialSettings={{ startDate: '06/01/2021', endDate: '12/31/2021' }}>
                <Form.Control type="text" className="custom_input" />
            </DateRangePicker>

            <Dropdown.Item><Button className="drop_btn" >Search</Button></Dropdown.Item>
            </DropdownButton>
        </div>

        <Button className="btn srch_btn"><Link to="/home">Filter</Link></Button>
        <Button className="btn srch_btn me-0 btn-notify-width" >Notify Manufacturer </Button>
      </Form>   
    
  </Container>
  )
}

import React , {useState } from "react";
import { useHistory } from "react-router-dom";
import {Form, Button} from 'react-bootstrap';
import './Style.scss';
import data from '../../Data/Config.json';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header'; 

export default function User(props) {

  const history = useHistory();
  const [selected, setSelected] = useState("0");

  const handlesubmit = () => {
       if(selected === "0"){
        alert("please select user");
        return false;
      }else{
        localStorage.setItem( "user_type" , selected);
        history.push("/home");
      }
  }


 return (
   <>
   <Header /> 
   <div className="login_bg">
    <div className= "user--type">      
          <h3>Login</h3>
          <Form.Select value={selected} onChange={(e)=>{setSelected(e.target.value)}}>    
              <option disabled value="0">Select User Type</option>
              { data.users.map((obj,index)=>(
                <option key={index} value={obj.id}>{obj.name}</option>
              ))}    
              
          </Form.Select>
          <Button onClick={handlesubmit}>Submit</Button>
      </div> 
   </div>
        
    <Footer />
  </>
  );
}

import React, { useState } from "react";
import Navbar from 'react-bootstrap/Navbar';
import { Container,Image } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import Avatar1 from './../Static/Images/Vector.jpg';
import './Style.scss';
import {Link, useHistory} from 'react-router-dom';
import data from '../Data/Config.json';
import vision from '../Static/vision_logo.png';


export default function Header(){
    const user_type = localStorage.getItem("user_type") || '';
    const logo_image = localStorage.getItem("LogoURL") || '';
    let username = '';
    if (user_type !== ""){
        username = data[user_type].user;
    } 
    const[show, setShow]= useState(false);
    const[logout, setLogout] = useState(false);
    const history = useHistory();
    function handleShow(){setShow(!show);}
    function handleLog(){setLogout(!logout);}

    const handlelogout=()=> {
            localStorage.removeItem("user_type");
            localStorage.removeItem("LogoURL");
            history.push("/log-in");
    }
    
    return(
        <>

    { user_type !== "" ?        
        <Navbar expand="lg" className="header-container">
            <Container >
                <Link to="/home" className="LogoResponsive navbar-brand">
                    {logo_image !== "" ? <Image src={logo_image} /> : <Image src={data.logoURl} />}                    
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <div className="notification_bell" onClick={handleShow}>
                        <FontAwesomeIcon icon={faBell} />
                        <Badge>1</Badge>
                        {show=== true && <>
                            <div className="notification_box">
                                <div className="Headerr">
                                    <p>Notifications</p>
                                    <Badge>1</Badge>
                                </div>
                                <div className="contentt">
                                    <div className="notification_item">
                                        <div className="avtar1">
                                            <Image src={Avatar1} />
                                        </div>
                                        <div className="item_details">
                                            <p className="itemname">Heinz Home Style Turkey...</p>
                                            <p className="itemstatus">Item previously sold</p>
                                            <p className="timestamp">1 min ago</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>}
                    </div>                    
                        <div className="avtar_img"><p>{username}</p></div>
                        <div className="user_text" onClick={handleLog}>
                            <p>Welcome</p>
                            <p className="user_name">{username}</p>
                            {logout=== true && <>
                            <div className="menu_box">                               
                                    <Link to="" onClick={handlelogout}><FontAwesomeIcon icon={faSignOut} /> Logout</Link>                                                                                       
                            </div>
                        </>} 
                        </div>         
                                  
                </Navbar.Collapse>

            </Container>
        </Navbar>  
        :
         <Navbar expand="lg" className="header-container headerout">
         <Container >
             <Navbar.Brand href="" className="LogoResponsive1">
                <Image src= {vision} alt="logo" />                 
             </Navbar.Brand>           
         </Container>
     </Navbar>                 
          
}
        </>
    )
}
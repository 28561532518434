import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import Header from './Header';


export default function Configuration(){
    const[selected, setSelected] = useState("")
    const[success, setSuccess] = useState(false)
    function handlesubmit(e){
        const newURL = selected    
        if(newURL !== ""){
            localStorage.setItem("LogoURL", newURL);
            setSuccess(true)
        }else{
            return false;
        }
    }

    return(
        <>       
        <Header />
        <Container >
            <div className= "config--container">
            <Row>
                <Col lg={10}>
                    <Form.Group className="mb-0" controlId="formBasicEmail">                        
                        <Form.Control type="text" placeholder="Enter Logo Url" onChange={(e)=>{setSelected(e.target.value)}}/>
                    </Form.Group>
                   {success === true && <p>Logo successfully updated!</p>} 
                </Col>
                <Col lg={2}>
                    <Button variant="primary" onClick={handlesubmit}>Save</Button>
                </Col>
            </Row> 
            </div>                
        </Container>          

        </>
    )
}
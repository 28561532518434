import React, { useEffect, useState } from 'react';
import Header from './../../Components/Header';
import { Container } from "react-bootstrap";
import SearchBar from './../../Components/SearchBar';
import Tabletree from './../../Components/TreeTable/Tabletree';
import dataTable from '../../Data/DataTable.json';
import { useParams } from 'react-router';
import '../../Components/TreeTable/Style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';


export default function ManageItem() {
  const[products,setProducts]=useState([]);
  const[allProducts,setAllProducts]=useState([]);
  const[currentDataItem,setCurrentDataItem] = useState();
  const[searchParam,setSearchParam] = useState('');  
  const params = useParams();
  const getProductItem = ()=>{
    if(Object.keys(params).length !== 0){
      setProducts(dataTable[params['id']].productlist);
      setAllProducts(dataTable[params['id']].productlist);
    }else{
      let dataArray:any = [];
      Object.keys(dataTable).forEach((element)=>{
       
        dataArray.push(...dataTable[element].productlist)
      });
      setAllProducts(dataArray);
      setProducts(dataArray);
    }
  }
  useEffect(()=>{    
    getProductItem();
  },[params]);

   
    let total_volume = 0;
    let total_value = 0;
   products && products.forEach((obj, index) => {      
        total_volume += parseInt(obj['volume'])
        let row_value = (obj['value']+"").replace("£" , "");
        total_value += parseFloat(row_value)
    })
    const handleSearchItems = (searchValue) => {
      let search = searchValue.toLowerCase();
     
      let dataArray = []; 
      allProducts.forEach((element,index) => {
          if((((element['item_name']+"").toLowerCase()).includes(search)) || 
          (((element['id']+"").toLowerCase()).includes(search)) || 
          (((element['batch_no']+"").toLowerCase()).includes(search))){
            dataArray.push(element);
          }         
      }); 
      setSearchParam(search);
      if(search === ""){
        setProducts(dataArray);
      } else{
        setProducts(dataArray); 
      }            
    }  
    const setDataMarker = (marker)=>{
      let dataArray:any = [];
      console.log("dataTable",dataTable);
      marker.forEach((keys) => {
        
       let allProducts = dataTable[keys]['productlist'];			
        allProducts.forEach((element,index) => {
          if( (((element['item_name']+"").toLowerCase()).includes(searchParam)) || 
              (((element['id']+"").toLowerCase()).includes(searchParam)) || 
              (((element['batch_no']+"").toLowerCase()).includes(searchParam)) )
              {
                dataArray.push(element);
              }        
           
          }); 
      });
      setProducts(dataArray);
      setAllProducts(dataArray);
    } 
    return (
    <>
    <Header />    
    <SearchBar setDataMarker={setDataMarker} handleSearchItems={handleSearchItems} currentDataItem={currentDataItem} setCurrentDataItem={setCurrentDataItem} selectedLoc={params&&params['id']} />
    <Container>
      <div className="total_cal">
        <Link to="/home"><FontAwesomeIcon icon={faArrowLeft} className="me-1"/> Go Back</Link>
        <div>
          <div className="total_val"><p>Total Volume: <span>{total_volume}</span></p></div>              
          <div className="total_val1"><p>Total Value: <span>£{total_value}</span></p></div>
        </div>
      </div>

      <Tabletree products={products}/>     
    </Container> 
    </>
  )
}

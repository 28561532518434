import React,{useState} from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { UserContext } from './lib/UserContext';
import './App.css';
import HomePage from "./Pages/Home/HomePage";
import ManageItem from "./Pages/ManageItems/ManageItem";
import User from "./Pages/User/User";
import Buyitem from "./Pages/Buyitem/Buyitem";
import Configuration from "./Components/Configuration";
import CustomerBuy from "./Pages/Buyitem/CustomerBuy";


function App() {
	const [loginUser, setLoginUser] = useState(null);
  
  return (
    <UserContext.Provider value={[loginUser, setLoginUser]}>
    <Router>
      <Switch>
          <Route
          exact
          path="/"
          render={() => <Redirect to="/log-in"/>}
        />
        <PublicRoute path="/log-in" component={User} />
        <PrivateRoute path="/home" component={HomePage} />
        <PrivateRoute path="/manageitem/:id" component={ManageItem} />
        <PrivateRoute exact path="/manageitem/" component={ManageItem} />        
        <PrivateRoute path="/buy-item" component={Buyitem} />
        <PrivateRoute path="/customer-buy" component={CustomerBuy} />
        <PrivateRoute path="/configuration" component={Configuration} />
       </Switch>
    </Router>
	 </UserContext.Provider>
  );
  
  function PublicRoute({ component, ...rest } : any) {
    return (
      localStorage.getItem('user_type') ?
      <Redirect to="/home" />
      :
      <Route
        {...rest}
        render={props =>
         (
            React.createElement(component, props)
          )
        }
      />
    );
  }
  function PrivateRoute({ component, ...rest } : any) {
    return (
	localStorage.getItem('user_type') ?
      <Route
        {...rest}
        render={props =>
         (
            React.createElement(component, props)
          )
        }
      />
	  :
	  <Redirect to="/log-in" />
	  
    );
  }
}

export default App;

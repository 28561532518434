import React from "react";
import { Container } from "react-bootstrap";


export default function Footer(){
      
    return(
        <>                  
        <Container fluid className="footer-container">
            <p>©2022 by The Vision Chain</p>                   
        </Container>         
        </>
    )
}